import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: { marginTop: '4px' } }
const _hoisted_2 = ["name", "value", "checked", "disabled"]
const _hoisted_3 = {
  key: 0,
  style: { marginLeft: '16px' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree_check_box_node = _resolveComponent("tree-check-box-node", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, [
      _createElementVNode("input", {
        type: "checkbox",
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInputCheckBox && _ctx.onInputCheckBox(...args))),
        name: _ctx.node.name,
        value: _ctx.node.value,
        checked: _ctx.node.isChecked,
        disabled: _ctx.disabled
      }, null, 40, _hoisted_2),
      _createTextVNode(" " + _toDisplayString(_ctx.node.name), 1)
    ]),
    (_ctx.node.childs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.node.childs, (childNode) => {
            return (_openBlock(), _createBlock(_component_tree_check_box_node, {
              key: childNode.value,
              onCheck: ($event: any) => (_ctx.$emit('check', $event, childNode)),
              disabled: _ctx.disabled,
              node: childNode
            }, null, 8, ["onCheck", "disabled", "node"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}