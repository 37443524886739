import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree_check_box_node = _resolveComponent("tree-check-box-node")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nodeItems, (node) => {
      return (_openBlock(), _createBlock(_component_tree_check_box_node, {
        key: node.value,
        onCheck: _ctx.onCheck,
        disabled: _ctx.disabled,
        node: node
      }, null, 8, ["onCheck", "disabled", "node"]))
    }), 128))
  ]))
}